// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-communities-js": () => import("./../../../src/pages/communities.js" /* webpackChunkName: "component---src-pages-communities-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-repository-js": () => import("./../../../src/pages/repository.js" /* webpackChunkName: "component---src-pages-repository-js" */),
  "component---src-pages-skills-framework-overview-js": () => import("./../../../src/pages/skills-framework/overview.js" /* webpackChunkName: "component---src-pages-skills-framework-overview-js" */),
  "component---src-pages-skills-framework-skills-domain-model-js": () => import("./../../../src/pages/skills-framework/skills-domain-model.js" /* webpackChunkName: "component---src-pages-skills-framework-skills-domain-model-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-skill-template-js": () => import("./../../../src/templates/skill-template.js" /* webpackChunkName: "component---src-templates-skill-template-js" */)
}

